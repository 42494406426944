import {
  faEnvelope,
  faMapMarkerAlt,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import FormItem from "./helpers/form-item";
import selectItems from "./select-items";
const validators = {
  /**
   * @param {{value:String}} props
   * @returns
   */
  email: (value) =>
    !value ||
    !!value?.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]*[^.\s]+\.[a-zA-Z]{2,4}$/),
  phone: (value) => !value || isPossiblePhoneNumber(value),
  standard: (value) => !value || !(value.length === 0),
};
const formItemMapping = {
  name: new FormItem("name", "text", true, {
    icon: faUserAlt,
    autoFocus: true,
    validator: validators.standard,
  }),
  email: new FormItem("email", "email", false, {
    icon: faEnvelope,
    validator: validators.email,
  }),
  phone: new FormItem("phone", "phone", true, { validator: validators.phone }),
  acceptedChrist: new FormItem("acceptedChrist", "select", true, {
    selectOptions: selectItems.acceptedChrist,
    translationField: "accepted-christ",
  }),
  contactMethod: new FormItem("contactMethod", "select", true, {
    selectOptions: selectItems.contactMethod,
    translationField: "best-contact",
  }),
  wantsToConnect: new FormItem("wantsToConnect", "checkbox", false, {
    translationField: "wants-to-connect",
  }),
  city: new FormItem("city", "location", false, {
    icon: faMapMarkerAlt,
    isToggled: true,
    validator: validators.standard,
  }),
  state: new FormItem("state", "location", false, {
    isToggled: true,
    validator: validators.standard,
  }),
  postal: new FormItem("postal", "postal", false, {
    isToggled: true,
  }),
  district: new FormItem("district", "location", false, {
    isToggled: true,
    validator: validators.standard,
  }),
  subdistrict: new FormItem("subdistrict", "location", false, {
    isToggled: true,
  }),
  gender: new FormItem("gender", "select", true, {
    selectOptions: selectItems.gender,
    isToggled: true,
  }),
  ageRange: new FormItem("ageRange", "select", true, {
    selectOptions: selectItems.ageRange,
    isToggled: false,
    translationField: "age-range",
  }),
  interestedIn: new FormItem("interestedIn", "text", true, {
    translationField: "interested-in",
  }),
  rednoteId: new FormItem("rednoteId", "text", false, {
    translationField: "rednote-id",
  }),
};

export default formItemMapping;
